import React, { Component } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";

class GoogleBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
      accessToken: ""
    };

    this.login = this.login.bind(this);
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  login(response) {
    const { googleResponse } = this.props;
    if (response.accessToken) {
      googleResponse(response);
      this.setState((state) => ({
        isLogined: true,
        accessToken: response.accessToken
      }));
    }
  }

  logout(response) {
    this.setState(state => ({
      isLogined: false,
      accessToken: ""
    }));
  }

  handleOnSuccess(response) {
    const { submit } = this.props;
    this.login(response);
    submit();
  }

  handleLoginFailure(response) {
    if (response?.error === "access_denied") {
      alert("Ocurrió un error al iniciar sesión");
    }
  }

  handleLogoutFailure(response) {
    alert("Ocurrió un error al cerrar la sesión");
  }
  render() {
    const { text, logoutText } = this.props;
    const scope =
      "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/calendar";
    return (
      <div>
        {this.state.isLogined ? (
          <GoogleLogout
            clientId={process.env.REACT_APP_CLIENT_ID}
            buttonText={logoutText}
            onLogoutSuccess={this.logout}
            onFailure={this.handleLogoutFailure}
          />
        ) : (
          <GoogleLogin
            clientId={process.env.REACT_APP_CLIENT_ID}
            buttonText={text}
            onSuccess={this.handleOnSuccess}
            onFailure={this.handleLoginFailure}
            cookiePolicy={"single_host_origin"}
            responseType="code,token"
            scope={scope}
            accessType="offline"
            prompt="consent"
          />
        )}
      </div>
    );
  }
}

export default GoogleBtn;
