import { sendRequest } from "util/utils";
import storage from "services/storage";

const login = async data => {
  const route = "/login/google";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const getUserList = async (historical = false, page = 1, term = "") => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users?historical=${historical}&page=${page}&term=${term}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const createUser = async (user) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users`,
    request = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        Authorization: token
      },
      body: user
    };
  return await sendRequest(route, request);
};

const editUser = async (userId, user) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users/${userId}`,
    request = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        Authorization: token
      },
      body: user
    };
  return await sendRequest(route, request);
};

const editProfile = async (user) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users`,
    request = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        Authorization: token
      },
      body: user
    };
  return await sendRequest(route, request);
};

const getUser = async userId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${userId}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getCurrentUser = async userId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/authenticated_user`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const changeUserStatus = async (userId, status) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${userId}/status`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      },
      body: JSON.stringify({ status })
    };
  return await sendRequest(route, request);
}

const searchUsers = async (term = "") => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/search-collaborators?term=${term}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getWorkAreasList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_areas`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const getWorkGroupsList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/selector`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const getOrganizationsList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/organizations`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const getRolesList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/roles`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

export default {
  login,
  getUserList,
  createUser,
  editUser,
  editProfile,
  getUser,
  changeUserStatus,
  searchUsers,
  getWorkAreasList,
  getWorkGroupsList,
  getOrganizationsList,
  getRolesList,
  getCurrentUser
};
