import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import storage from "services/storage";

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };
  
  logout = () => {
    storage.removeToken();
    this.props.history.push("/login");
    this.setState({open: false});
  }

  render() {
    const data = storage.getData();
    const name = data?.user?.name || "Usuario sin nombre";
    const imgSrc = data?.user?.photography_custom 
                  || data?.user?.photography
                  || 'https://via.placeholder.com/150x150'

    return (
      <div className="ml-2 user-profile d-flex flex-row align-items-center">
          <div className="user-detail d-flex flex-row align-items-center">
            <h4 className="user-name mr-2"><b>{data?.user?.rol || ""}</b></h4>
            <span className="d-inline-block ml-1 mr-1" style={{backgroundColor: "#CCCCCC", width:"1px"}}>&nbsp;</span>
            <h4 className="user-name mr-2 ml-2">{name}
            </h4>
          </div>
          <Avatar
            alt='...'
            src={imgSrc}
            className="user-avatar size-25"
            onClick={this.handleClick}
          />
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 120,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}>
          {/* <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.profile"/>
          </MenuItem> */}
          <MenuItem onClick={() => {
            this.logout();
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

            <IntlMessages id="popup.logout"/>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default UserInfo;


