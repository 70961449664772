import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import DisplayDate from 'components/wall/DisplayDate'
import api from "services/api";
import {
  NOTIFICATION_NEW_ACTIVITY,
  NOTIFICATION_PROCEDURE_VALIDATION,
  NOTIFICATION_PROCEDURE_VALIDATION_APPROVE,
  NOTIFICATION_PROCEDURE_VALIDATION_REJECT
} from "constants/Notifications";

const NotificationItem = ({notification, history, deleteNotification}) => {
  const {
    id,
    work_group_id,
    user_photography,
    user_photography_custom,
    user_name,
    type,
    title,
    procedure_id,
    created_at
  } = notification;
  const clickNotification = async () => {
    //Eliminar notificación
    const response = await api.notifications.deleteNotification(id);
    if (response.success) {
      deleteNotification(id);
      if (notification.type === NOTIFICATION_NEW_ACTIVITY) {
        // Redirigir al grupo de trabajo
        history.push(`/app/work-groups/${work_group_id}`);
      } else {
        // Redirigir a la ficha de trámite
        history.push(`/app/procedures/${procedure_id}`);
      }
    }
  }
  const getNotificationText = () => {
    switch (type) {
      case NOTIFICATION_NEW_ACTIVITY:
        return  <p className="sub-heading mb-0"><b>{user_name}</b> registró {title}</p>
      case NOTIFICATION_PROCEDURE_VALIDATION:
      case NOTIFICATION_PROCEDURE_VALIDATION_APPROVE:
      case NOTIFICATION_PROCEDURE_VALIDATION_REJECT:
        return <p className="sub-heading mb-0">{title}</p>
    }
  }
  const getIconFromStatus = () => {
    switch (type) {
      case NOTIFICATION_PROCEDURE_VALIDATION:
        return " icon-SCADMON_Estatus_por-autorizar1 jr-fs-xxs";
      case NOTIFICATION_PROCEDURE_VALIDATION_APPROVE:
        return "check-circle";
      case NOTIFICATION_PROCEDURE_VALIDATION_REJECT:
        return "close-circle";
      default:
        return "close-circle";
    }
  }
  return (
    <li className="media" onClick={clickNotification} style={{cursor: "pointer"}}>
      {
        type === NOTIFICATION_NEW_ACTIVITY
        ? <Avatar
          alt="user"
          src={user_photography_custom || user_photography}
          className=" mr-2"
        />
        : <div className="MuiAvatar-root MuiAvatar-circle  mr-2">
          <i style={{fontSize: "2.5rem"}} class={`zmdi zmdi-${getIconFromStatus()}`} />
        </div>
      }
      <div className="media-body align-self-center">
        {getNotificationText()}
        <span className="meta-date">
          <i className={`zmdi zmdi-time zmdi-hc-fw`} />
        </span>
        <span className="meta-date"><small>{` `}<DisplayDate date={created_at} /></small></span>
      </div>
    </li>
  );
};

export default NotificationItem;
