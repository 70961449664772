import { sendRequest } from "util/utils";
import storage from "services/storage";

const list = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getWorkGroup = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const modifyWorkGroupUsers = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/${id}/collaborators`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
};

const createWorkGroup = async data => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/work_groups`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const deleteWorkGroup = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/${id}`,
    request = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const createActivity = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/activities/${id}`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const getActivities = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/activities/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

export default {
  list,
  getWorkGroup,
  deleteWorkGroup,
  modifyWorkGroupUsers,
  createWorkGroup,
  createActivity,
  getActivities
};
