import {
  CLOSE_SNACKBAR,
  USER_REQUEST,
  WORK_GROUP_REQUEST,
  PROCEDURE_REQUEST
} from "constants/ActionTypes";

const initialState = {
  user: {
    message: ""
  },
  workGroup: {
    message: ""
  },
  procedure: {
    message: ""
  }
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        user: {
          message: action.payload.message,
          success: action.payload.success || state.user.success
        }
      };
    case WORK_GROUP_REQUEST:
      return {
        ...state,
        workGroup: {
          message: action.payload.message,
          success: action.payload.success || state.workGroup.success
        }
      }
    case PROCEDURE_REQUEST:
      return {
        ...state,
        procedure: {
          message: action.payload.message,
          success: action.payload.success || state.procedure.success
        }
      }
    case CLOSE_SNACKBAR:
      return {
        user: {
          message: ""
        },
        workGroup: {
          message: ""
        },
        procedure: {
          message: ""
        }
      };
    default:
      return state;
  }
};

export default api;
