import numeral from "numeral";
import baseUrl from "../constants/Api";
import storage from "../services/storage";
import validators from "./validators";
import React from "react";

const cloneObject = object => {
  return JSON.parse(JSON.stringify(object));
};

export const sendRequest = async (route, request, isFile = false) => {
  let response;
  try {
    response = await fetch(baseUrl + route, request);
    if (response.status === 401) {
      return await refreshToken(route, request, isFile);
    }
  } catch (error) {
    return { success: false };
  }
  try {
    const data = (isFile && response.status === 200) ? await response.blob() : await response.json();
    return data;
  } catch (error) {
    return { success: false };
  }
};

const refreshToken = async (route, request, isFile) => {
  let response;
  try {
    response = await fetch(baseUrl + "/refreshToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ refresh_token: storage.getRefreshToken() })
    });
    response = await response.json();
    if (response.success) {
      storage.updateToken(response.token);
      const newRequest = {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${storage.getToken()}`
        }
      };
      response = await fetch(baseUrl + route, newRequest);
      const data = isFile ? await response.blob() : await response.json();
      return data;
    } else {
      storage.removeToken();
      return {
        success: false,
        logout: true
      };
    }
  } catch (error) {
    storage.removeToken();
    return {
      success: false,
      logout: true
    };
  }
};

const formatMoney = number => {
  const myNumeral = numeral(number);
  return myNumeral.format("$0,0.00");
};

const formatDecimals = number => {
  const myNumeral = numeral(number);
  return myNumeral.format("0,0.00");
};

const formatDateToString = date => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString("es-MX", options);
};

const getFormattedDate = (date, symbol = "/") => {
  return `${date.getDate()}${symbol}${date.getMonth() +
    1}${symbol}${date.getFullYear()}`;
};

const addDateAndFormat = (date, years = 0, months = 0, days = 0) => {
  const dateObj = new Date(date);
  dateObj.setFullYear(dateObj.getFullYear() + years);
  dateObj.setMonth(dateObj.getMonth() + months);
  dateObj.setDate(dateObj.getDate() + days);

  return getFormattedDate(dateObj);
};

const formatStringToDate = date => {
  let d = date.split("/");
  return `${d[1]}/${d[0]}/${d[2]}`;
};

/**
 * Recibe la cantidad y el tipo y retorna una cadena de la forma '5 litros' o '300 pesos'
 *
 * @param {number} amount
 * @param {string} type ['lt', 'money']
 */
const formatCostString = (amount, type) => {
  let costString = `${amount} `;
  costString += type === "lt" ? "litros" : "pesos";
  return costString;
};

const getSelectItem = (item, options) => {
  options.some(option => {
    if (option.value === item) {
      item = option;
      return true;
    }
  });
  return item;
};

//Obtener los items para un select multiple
const getSelectItems = (items, options) => {
  const selectedItems = [];
  items = items || [];
  options.forEach(option => {
    if (items.includes(option.value)) {
      selectedItems.push(option);
    }
  });
  return selectedItems;
};

const getLabelOptionFromValue = (item, options) => {
  options.some(option => {
    if (option.value === item) {
      item = option.label;
      return true;
    }
  });
  return item;
};

const getColorByStatus = status => {
  switch (status) {
    case "Activo":
      return "#59c74f";
    case "Concluido negativamente":
      return "#d44629";
    case "Concluido positivamente":
      return "#537ea2";
    case "Concluido positivamente con invierte":
      return "#d2a7fe";
    case "Concluido positivamente sin seguimiento":
      return "#e56520";
    default:
      return "gray";
  }
};

const getStylesByType = type => {
  var obj = {};
  switch (type) {
    case "Cambio en proyecto":
      obj = {
        color: "#9991d4",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
              clip-rule="evenodd"
            />
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
            <circle cx="8" cy="4.5" r="1" />
          </>
        )
      };
      break;
    case "Creación de proyecto":
      obj = {
        color: "#948ee3",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
            />
            <path
              fill-rule="evenodd"
              d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
            />
          </>
        )
      };
      break;
    case "SLA":
      obj = {
        color: "#bbb7ed",
        icon: (
          <>
            <path d="M8 16a2 2 0 002-2H6a2 2 0 002 2z" />
            <path
              fill-rule="evenodd"
              d="M8 1.918l-.797.161A4.002 4.002 0 004 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 00-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0A5.002 5.002 0 0113 6c0 .88.32 4.2 1.22 6z"
              clip-rule="evenodd"
            />
          </>
        )
      };
      break;
    case "Correo entrante":
      obj = {
        color: "#a8a3e8",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M5.854 5.646a.5.5 0 010 .708L3.207 9l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M13.5 2.5a.5.5 0 01.5.5v4a2.5 2.5 0 01-2.5 2.5H3a.5.5 0 010-1h8.5A1.5 1.5 0 0013 7V3a.5.5 0 01.5-.5z"
              clip-rule="evenodd"
            />
          </>
        )
      };
      break;
    case "Correo recibido":
      obj = {
        color: "#a8a3e8",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M5.854 5.646a.5.5 0 010 .708L3.207 9l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M13.5 2.5a.5.5 0 01.5.5v4a2.5 2.5 0 01-2.5 2.5H3a.5.5 0 010-1h8.5A1.5 1.5 0 0013 7V3a.5.5 0 01.5-.5z"
              clip-rule="evenodd"
            />
          </>
        )
      };
      break;
    case "Correo enviado":
      obj = {
        color: "#a8a3e8",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M9.896 2.396a.5.5 0 000 .708l2.647 2.646-2.647 2.646a.5.5 0 10.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 00-.708 0z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M13.25 5.75a.5.5 0 00-.5-.5h-6.5a2.5 2.5 0 00-2.5 2.5v5.5a.5.5 0 001 0v-5.5a1.5 1.5 0 011.5-1.5h6.5a.5.5 0 00.5-.5z"
              clip-rule="evenodd"
            />
          </>
        )
      };
      break;
    case "Cambio en proyecto":
      obj = {
        color: "#9991d4",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
              clip-rule="evenodd"
            />
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
            <circle cx="8" cy="4.5" r="1" />
          </>
        )
      };
      break;
    case "Nota interna":
      obj = {
        color: "#b7b4e0",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
            />
            <path
              fill-rule="evenodd"
              d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
            />
          </>
        )
      };
      break;
    default:
      obj = {
        color: "#948ee3",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M7.879 11.015a.5.5 0 0 1 .242 0l6 1.5a.5.5 0 0 1 .037.96l-6 2a.499.499 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l6-1.5z"
            />
            <path d="M11.885 12.538l-.72-2.877C10.303 9.873 9.201 10 8 10s-2.303-.127-3.165-.339l-.72 2.877c-.073.292-.002.6.256.756C4.86 13.589 5.916 14 8 14s3.14-.411 3.63-.706c.257-.155.328-.464.255-.756zM9.97 4.88l.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.159-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12zm-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098z" />
          </>
        )
      };
      break;
  }
  return obj;
};
const getColorByLogType = type => {
  switch (type) {
    case "d":
      return "#e2e0f7";
    case "m":
      return "#d0ccee";
    case "x":
      return "#cecbf2";
    case "SLA":
      return "#bbb7ed";
    case "c":
      return "#a8a3e8";
    case "Cambio en proyecto":
      return "#9991d4";
    default:
      return "#948ee3";
  }
};

const getIconByFiletype = type => {
  switch (type) {
    case "png":
      return <i className="zmdi zmdi-image-o" />;
    case "jpeg":
      return <i className="zmdi zmdi-image-o" />;
    case "docsx":
      return <i className="zmdi zmdi-file-text" />;
    case "pdf":
      return <i className="zmdi zmdi-collection-pdf" />;
    case "pptx":
      return <i className="zmdi zmdi-airplay" />;
    default:
      return <i className="zmdi zmdi-attachment-alt" />;
  }
};

const isValidPhone = (number = "", cellphone = false) => {
  number = number.replace(/\s/g, "");
  if (number == "") {
    return true;
  }
  let phoneRegex;
  if (cellphone) {
    phoneRegex = /^\+?([0-9]{2})([0-9]{10})$/;
  } else {
    phoneRegex = /^([0-9]{10})$/;
  }
  return phoneRegex.test(number);
};

const monthDiff = (dateFrom, dateTo) => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

export default {
  cloneObject,
  formatMoney,
  formatDecimals,
  formatDateToString,
  formatStringToDate,
  getFormattedDate,
  addDateAndFormat,
  formatCostString,
  getSelectItem,
  getSelectItems,
  getStylesByType,
  getLabelOptionFromValue,
  getColorByStatus,
  isValidPhone,
  getIconByFiletype,
  validators,
  monthDiff
};
