import storage from "services/storage";
import { sendRequest } from "util/utils";

const getCategories = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/procedures/categories-areas",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getProceduresByCategory = async (category, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/byCategory/${category}?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getMostSearchedProcedures = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/procedures/most_searched",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getMostFrequentProcedures = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/procedures/most_frequent",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getProceduresByWorkArea = async (workAreaId, page, selector = false) => {
  const route = selector
    ? `/procedures/by_administrative_area/${workAreaId}?selector=true`
    : `/procedures/by_administrative_area/${workAreaId}?page=${page}`;
  const token = `Bearer ${storage.getToken()}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getProceduresBySearch = async (search, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/by_word/${search}?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getProcedure = async (id, type) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}?type=${type}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const createProcedure = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const createProcedureRequest = async (procedureId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${procedureId}/requests`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const editProcedure = async ({ id, data }) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const approveProcedure = async (id, status, non_approved_reason) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}/approve`,
    request = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ status, non_approved_reason }),
    };
  return await sendRequest(route, request);
};

const categoriesAreas = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/categories-areas`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const deleteProcedure = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/${id}`,
    request = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getMyProceduresList = async (page = 1) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getResponsibleProceduresList = async (page = 1) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/responsible?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getValidatorProceduresList = async (page = 1) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/validator?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getMyResponsibleProceduresList = async (page = 1) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/my_responsible_procedures?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const updateProcedureRequest = async (id, procedure) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/${id}`,
    request = {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: procedure,
    };
  return await sendRequest(route, request);
};

const downloadProcedureResponse = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/my-procedures/response/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request, true);
};

const satisfactionSurvey = async (value) => {
  const data = new FormData();
  data.append("good", false);
  data.append("regular", false);
  data.append("bad", false);
  data.set(value, true);

  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey`,
    request = {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const satisfactionSurveyStats = async (value) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getSurveyStatus = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey/status`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const updateProceduresStatus = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/updateProceduresFromExcel?page=${page}`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getProcedureComments = async (id, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/questions?procedure_id=${id}&page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const addComment = async (data, id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/question?procedure_id=${id}`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    };
  return await sendRequest(route, request);
};

const replyComment = async (data, procedureId, commentId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/answer?procedure_id=${procedureId}&question_id=${commentId}`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    };
  return await sendRequest(route, request);
};

const loadMessages = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/${id}/messages`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const sendMessage = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/messages`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const markAsRead = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/procedures/requests/messages/${id}`,
    request = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getSurveyPerYear = async (year) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey/${year}`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

const getAllSurveys = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/satisfaction_survey`,
    request = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
  return await sendRequest(route, request);
};

export default {
  getCategories,
  getProceduresByCategory,
  getMostSearchedProcedures,
  getMostFrequentProcedures,
  getProceduresByWorkArea,
  getProceduresBySearch,
  getProcedure,
  createProcedure,
  createProcedureRequest,
  deleteProcedure,
  editProcedure,
  approveProcedure,
  categoriesAreas,
  getMyProceduresList,
  getResponsibleProceduresList,
  getValidatorProceduresList,
  getMyResponsibleProceduresList,
  updateProcedureRequest,
  downloadProcedureResponse,
  satisfactionSurvey,
  satisfactionSurveyStats,
  getSurveyStatus,
  updateProceduresStatus,
  getProcedureComments,
  addComment,
  replyComment,
  loadMessages,
  sendMessage,
  markAsRead,
  getAllSurveys,
  getSurveyPerYear,
};
